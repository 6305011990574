import React from "react";
import Logo from '../../assets/logo.png'

export default function AboutRegistration() {
  return (
    // <div>
    //     <img src="" alt="" />
    //     <h4 className='my-5'>
    //         The Youth Connect Initiative Program to provide underserved youths
    //         with employability skills and connect opportunities through sponsored
    //         and subsidised programs
    //     </h4>
    //     <h5>Criteria to enroll</h5>

    //     <ol className='ps-0'>
    //         <li>
    //             <p>At least a school leaving certificate</p>
    //         </li>
    //         <li>
    //             <p>Must be between 18 to 35 years old</p>
    //         </li>
    //         <li>
    //             <p>Must have access to an Internet enabled phone</p>
    //         </li>
    //         <li>
    //             <p>Must have a valid reason for wanting to join</p>
    //         </li>
    //         <li>
    //             <p>
    //                 Must have a valid means of Identification - NIN, Voter's card,
    //                 driver's license or bank account details.
    //             </p>
    //         </li>
    //     </ol>
    // </div>
    <div className=""style={{paddingTop:"90px"}}>
    <div className="pt-5" style={{textAlign: 'center'}}>
      <img
        src={Logo}
        alt=""
        className=""
        style={{ margin: "0px auto 50px", textAlign: "center", width: '', border: '' }}
        width="290px"
      />{" "}
      <h4
      style={{
        // backgroundColor: "#F9DCAD",
        background: 'darkgreen',
        fontSize: "22px",
        color: 'white',
        padding: '.5em .4em',
        width: 'fit-content',
        textAlign: 'left',
        margin: 'auto'
      }} 
      >Join our pool of Training Providers</h4>
      <br />
      {/* <small
        style={{
          backgroundColor: "#F9DCAD",
          fontSize: "15px",
          margin: "100px 0px",
        }}
        className="mt-5 py-2 px-2 rounded-2"
      >
        Introducing the 3 Million Technical Talent Program
      </small> */}
      {/* <h4 className="my-4">
      Partner with Us
      </h4> */}
      <h5 style={{textAlign: 'left'}}>
      The selection process for the the first batch of training providers for the prototype phase of the 3MTT program commenced on Nov 1st, 2023. 

All new training provider applications will be reviewed for the next batch in 2024. 


         </h5>
       <br />
      <h5 style={{textAlign: 'left'}}>Interested organisations are required to read the full requirements for training providers before applying.<a href="https://drive.google.com/file/d/1D1_SRWlBM6kR_XyVGGuPhbFRp25TuCUv/view?usp=drive_link" rel="noreferrer" target="_blank"> Download the document here</a></h5> 
      <br />

      {/* <ul className="ps-md-0">
        <li>
          <p>Direct or indirect funding to train technical talent</p>
        </li>

        <li>
          <p>Equipment, physical spaces & other infrastructural support</p>
        </li>

        <li>
          <p>Device support </p>
        </li>
       
<li>
    <p>Local & international job placements</p>
</li>
        <li>
          <p> Support for programme execution (Financial & Non-financial)</p>
        </li>
        <li>
          <p>
          And more
          </p>
        </li>
      </ul> */}
    </div>
    </div>
  );
}