import React, { useState } from "react";
import toast from "react-hot-toast";
import premblyLogo from "../../assets/premblyLogo.png";
import Alert from "./Alert";
import { localGov } from "./lg";
import { allCountriesData } from "./allCountries";
import { getCountryFlag } from "../utils/flags";
import { parsePhoneNumber } from 'awesome-phonenumber'

export default function Mainpage() {
  const [page, setPage] = useState(1);
  const [organizationName, setOrganizationName] = useState("");
  const [address, setAddress] = useState("");
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [links, setLinks] = useState("");
  const [pocName, setPocName] = useState("");
  const [pocAddress, setPocAddress] = useState("");
  const [pocPhone, setPocPhone] = useState("");
  const [programYear, setProgramYear] = useState("");
  const [staffNumber, setStaffNumber] = useState("");
  const [expertiseCourse, setExpertiseCourse] = useState([]);
  const [track, setTrack] = useState("");
  const [delivery, setDelivery] = useState("");
  const [link, setLink] = useState("");
  const [proof, setProof] = useState("");
  const [infrastructure, setInfrastructure] = useState("");
  const [capacity, setCapacity] = useState("");
  const [model, setModel] = useState("");
  const [network, setNetwork] = useState("");
  const [orgFullname, setOrgFullname] = useState("");
  const [physical, setPhysical] = useState("");
  const [trainingCapacity, setTrainingCapacity] = useState("");
  const [toolInfra, setToolInfra] = useState("");
  const [capacityRecord, setCapacityRecord] = useState("");
  const [orgPoc, setOrgPoc] = useState("");
  const [orgFellow, setOrgFellow] = useState("");
  const [emailPoc, setEmailPoc] = useState("");
  const [staffOrg, setStaffOrg] = useState("");
  const [teamOverview, setTeamOverview] = useState("");
  const [trackRecord, setTrackRecord] = useState("");
  const [deliveryTraining, setDeliveryTraining] = useState("");
  const [phonePoc, setPhonePoc] = useState("");
  const [orgLink, setOrgLink] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [countryCode, setCountryCode] = useState("NG")
  const [orgType, setOrgType] = useState("")

  // const [page, setPage] = useState(1);
  const [firstName, setFirstName] = useState("");
  const [orgName, setOrgName] = useState("");
  const [lastName, setLastName] = useState("");
  const [fullName, setFullName] = useState("");
  // const [isSubmitting, setIsSubmitting] = useState(false);
  const [gender, setGender] = useState("");
  const [contribution, setContribution] = useState("");
  const [emailAddress, setEmailAdress] = useState("");
  const [partnership, setPartnership] = useState("");
  const [designation, setDesignation] = useState("");
  // const [age, setAge] = useState("");
  const [agreement, setAgreement] = useState(false);
  const [viewableLink, setViewableLink] = useState("");
  const [additionInfo, setAdditionInfo] = useState("");
  const [initiatives, setInitiatives] = useState("");
  const [benefit, setBenefit] = useState("");
  const [orgGoals, setOrgGoals] = useState("");
  const [support, setSupport] = useState("");
  const [services, setServices] = useState("");
  const [allocation, setAllocation] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [ResidenceLocal, setResidenceLocal] = useState("");
  // const [email, setEmail] = useState("");
  const [amount, setAmount] = useState("");
  const [orgWebsite, setOrgWebsite] = useState("");
  // const [phone, setPhone] = useState("");
  // const [location, setLocation] = useState("");
  // const [address, setAddress] = useState("");
  // const [origin, setOrigin] = useState("");
  // const [educationalLevel, setEducationalLevel] = useState("");
  // const [technicalLevel, setTechnicalLevel] = useState("");
  // const [employmentStatus, setEmploymentStatus] = useState("");
  // const [course, setCourse] = useState("");
  // const [computerAccess, setComputerAccess] = useState("");
  // const [internetAccess, setInternetAccess] = useState("");
  // const [commitment, setCommitment] = useState("");
  // const [reason, setReason] = useState("");
  const [verifMethod, setVerifMethod] = useState("");
  const [ninData, setNinData] = useState("");
  const [bvnData, setBvnData] = useState("");
  const [githubLink, setGithubLink] = useState("");
  const [loading, setLoading] = useState(false);
  const [successModal, setSuccessModal] = useState(false);

  const [age, setAge] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [location, setLocation] = useState("");
  const [website, setWebsite] = useState("");
  //   const [address, setAddress] = useState("");
  const [origin, setOrigin] = useState("");
  const [educationalLevel, setEducationalLevel] = useState("");
  const [technicalLevel, setTechnicalLevel] = useState("");
  const [employmentStatus, setEmploymentStatus] = useState("");
  const [course, setCourse] = useState("");
  const [computerAccess, setComputerAccess] = useState("");
  const [internetAccess, setInternetAccess] = useState("");
  const [commitment, setCommitment] = useState("");
  const [lgData, setLgData] = useState([]);
  const [reason, setReason] = useState("");

  let no_of_staffs = [
    "Self-employed (1-person)",
    "1-10 employees",
    "11-50 employees",
    "51-200 employees",
    "201-500 employees",
    "501-1000 employees",
    "1001-5000 employees",
    "5001-10,000 employees",
    "10,000+ employees",
  ];

  let trainees = [
    "1-5 trainees",
    "6-10 trainees",
    "11-20 trainees",
    "21-30 trainees",
    "31-50 trainees",
    "51-70 trainees",
    "71-90 trainees",
    "91-100 trainees",
    "100+ trainees",
    "Other",
  ];

  let courses_offered = [
    "AI/ML",
    "Data Science",
    "Software Development",
    "Animation",
    "Cloud computing",
    "DevOps",
    "UI/UX",
    "Cyber Security",
    "Game Development",
    "Data Analysis & Visualization",
    "Product Management",
    "Quality Assurance",
  ];

  let nigerian_states = [
    "Abia",
    "Adamawa",
    "Akwa Ibom",
    "Anambra",
    "Bauchi",
    "Bayelsa",
    "Benue",
    "Borno",
    "Cross River",
    "Delta",
    "Ebonyi",
    "Edo",
    "Ekiti",
    "Enugu",
    "Gombe",
    "Imo",
    "Jigawa",
    "Kaduna",
    "Kano",
    "Katsina",
    "Kebbi",
    "Kogi",
    "Kwara",
    "Lagos",
    "Nasarawa",
    "Niger",
    "Ogun",
    "Ondo",
    "Osun",
    "Oyo",
    "Plateau",
    "Rivers",
    "Sokoto",
    "Taraba",
    "Yobe",
    "Zamfara",
    "Federal Capital Territory",
  ];

  let educationData = [
    "Primary school leaving certificate",
    "Primary school leaving certificate",
    "Ordinary National Diploma (OND)",
    "Higher National Diploma (HND)",
    "Bachelor's Degree",
    "Master's Degree",
    "Doctor of philosophy (PhD)",
    "No Education",
  ];

  let courseInterest = [
    "AI/ML",
    "Data Science",
    "Software Development",
    "Animation",
    "Cloud computing",
    "DevOps",
    "UI/UX",
    "Cyber Security",
    "Game Development",
    "Data Analysis & Visualization",
    "Product Management",
    "Quality Assurance",
  ];

  let getLG = (key) => {
    localGov?.forEach((val) => {
      if (val?.state === key) {
        setLgData(val?.lgas);
      }
    });
  };

  let next = () => {
    let email_reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,15})+$/;
    let url =
      /^(([a-z\d]([a-z\d-]*[a-z\d])?\.)+[a-z]{2,}|localhost)(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/i;

    if (page === 1) {
      //   if (!organizationName) {
      //     toast.error("Please input your organization name");
      //     return;
      //   }
      if (!orgName) {
        toast.error("Please input your organization name");
        return;
      }
      if (!gender) {
        toast.error("Please select organisation residence");
        return;
      }
      if (!ResidenceLocal) {
        toast.error("Please select Local government");
        return;
      }
      if (!designation) {
        toast.error("Please input address organization ");
        return;
      }
      if (!orgType) {
        toast.error("Please input your organization type");
        return;
      }
      if ((orgType && orgType !== "Government-Academic-Institution") && !emailAddress ) {
        toast.error("Please input your company registration number");
        return;
      }
      if (!phoneNumber) {
        toast.error("Please input your organisation website");
        return;
      }
    //   if (phoneNumber && url.test(phoneNumber) === false) {
    //     toast.error("Please input a valid website url");
    //     return;
    //   }

      setPage(2);
    }

    if (page === 2) {
      if (!orgLink) {
        toast.error("Please input organization links");
        return;
      }
      if (!orgPoc) {
        toast.error(
          "Please input your Full Name of your organisation (Point of Contact person)"
        );
        return;
      }
      if (!emailPoc) {
        toast.error(
          "Please input email address of your organisation (Point of Contact person)"
        );
        return;
      }
      if (emailPoc && email_reg.test(emailPoc) === false) {
        toast.error("Please input a valid email address");
        return;
      }
      if (!phonePoc) {
        toast.error(
          "Please input your Phone number of your organisation (Point of Contact person)"
        );
        return;
      }
      if(phonePoc && !phoneValidation?.valid){
        toast.error(
          "Please input a valid Phone number for your organisation (Point of Contact person)"
        );
        return;
      }
    //   if (phonePoc && phonePoc.length !== 11) {
    //     toast.error(
    //       "Invalid phone number"
    //     );
    //     return;
    //   }
      //   if (!origin) {
      //     toast.error("Please select your state of origin");
      //     return;
      //   }

      setPage(3);
    }

    if (page === 3) {
      if (!deliveryTraining) {
        toast.error("Please select your delivering training program");
        return;
      }
      if (!staffOrg) {
        toast.error("Please input the number of staff in your organisation");
        return;
      }
      // if (!expertiseCourse) {
      //   toast.error("Please input the expertise course to deliver");
      //   return;
      // }
      // if (!track) {
      //   toast.error("Please input track record of your organisation");
      //   return;
      // }
      //   if (!origin) {
      //     toast.error("Please select your state of origin");
      //     return;
      //   }

      setPage(4);
    }

    if (page === 4) {
      if (!expertiseCourse.length) {
        toast.error("Please choose course expertise");
        return;
      }
      // if (!link) {
      //   toast.error("Please input viewable link to training curriculum");
      //   return;
      // }
      // if (!proof) {
      //   toast.error("Please share delivery technical training");
      //   return;
      // }
      // if (!infrastructure) {
      //   toast.error("Please input the name/address of a partner");
      //   return;
      // }
      // if (!capacity) {
      //   toast.error("Please input the maximum capacity of your office space");
      //   return;
      // }
      // if (!model) {
      //   toast.error("Please input record to deliver blended learning model");
      //   return;
      // }
      //   if (!origin) {
      //     toast.error("Please select your state of origin");
      //     return;
      //   }

      setPage(5);
      // pushAllData();
    }

    if (page === 5) {
      if (!trackRecord) {
        toast.error("Please input prior track record ");
        return;
      }
      if (!teamOverview) {
        toast.error("Please input training delivery team");
        return;
      }
      if (!capacityRecord) {
        toast.error("Please input capacity and track record");
        return;
      }
      setPage(6);
    }

    if (page === 6) {
      if (!physical) {
        toast.error("Please input physical infrastructure ");
        return;
      }
      if (!toolInfra) {
        toast.error("Please input an overview of your training delivery team ");
        return;
      }
      if (!trainingCapacity) {
        toast.error("Please input maximum trainee capacity");
        return;
      }
      setPage(7);
    }

    if (page === 7) {
      if (!orgFellow) {
        toast.error("Please input an overview of your training delivery team ");
        return;
      }
      pushAllData();
    }
  };

  let prev = () => {
    if (page === 7) {
      setPage(6);
    } else if (page === 6) {
      setPage(5);
    } else if (page === 5) {
      setPage(4);
    } else if (page === 4) {
      setPage(3);
    } else if (page === 3) {
      setPage(2);
    } else if (2) {
      setPage(1);
    }
  };

  let pushAllData = () => {
    const selectedCourses = expertiseCourse?.join(",");
    var raw = JSON.stringify({
      // name: organizationName,
      // state: location,
      // address: address,
      // cac: registrationNumber,
      // website: website,
      // social_media: links,
      // poc_fullname: pocName,
      // poc_email: pocAddress,
      // poc_phone: pocPhone,
      // training_period: programYear,
      // staff_count: staffNumber,
      // courses: selectedCourses,
      // courses_track_record: track,
      // courses_overview: delivery,
      // courses_link: link,
      // infrastructure_proof: proof,
      // infrastructure_proof_partner: infrastructure,
      // trainee_capacity: capacity,
      // trainee_capacity_track_record: model,
      // network_mentor: network,

      name: orgName,
      state: gender,
      address: designation,
      cac: emailAddress,
      website: phoneNumber,
      social_media: orgLink,
      poc_fullname: orgPoc,
      poc_email: emailPoc,
    //   poc_phone: phonePoc,
      poc_phone: phoneValidation?.number?.international,
      training_period: deliveryTraining,
      staff_count: staffOrg,
      courses: selectedCourses,
      courses_track_record: trackRecord,
      courses_overview: teamOverview,
      courses_link: capacityRecord,
      infrastructure_proof: physical,
      infrastructure_proof_partner: toolInfra,
      trainee_capacity: trainingCapacity,
      trainee_capacity_track_record: orgFellow,
      selected_lga: ResidenceLocal
    });
 
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
 
    setIsSubmitting(true);

    fetch(
      "https://api.3mtt.training/api/v1/auth/register/provider",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        // toast.success("Form submitted successfully")
        setShowAlert(true);
        setIsSubmitting(false);
      setOrgName('');
       setGender('');
       setDesignation('');
      //  setEmailAddress('');
       setPhoneNumber('');
       setOrgLink('');
       setOrgPoc('');
       setEmailPoc('');
       setPhonePoc('');
       setDeliveryTraining('');
       setStaffOrg('');
      //  setSelectedCourses('');
       setTrackRecord('');
       setTeamOverview('');
       setCapacityRecord('');
       setPhysical('');
       setToolInfra('');
       setTrainingCapacity('');
       setOrgFellow('');
       setResidenceLocal('')
      })
      .catch((error) => toast.error("Something went wrong, please try again"));
  };

  const handleClose = () => {
    setShowAlert(false);
    setPage(1);
    window.location.href = 'https://3mtt.nitda.gov.ng/'
  };

  const phoneValidation = parsePhoneNumber( phonePoc, { regionCode: countryCode } );

  return (
    // <div className="main-app">
    //   <div className="col-md-8 col-lg-5 mx-auto py-5">
    //     <div className="card shadow" style={{ border: "none" }}>
    //       <div
    //         className="card-header text-center"
    //         style={{ background: "rgb(0, 125, 83)" }}
    //       >
    //         {/* <img
    //           src="https://www.commtech.gov.ng/images/FMCIDE_Logo.png"
    //           width="300px"
    //           alt=""
    //         /> */}
    //       </div>
    //       <div className="card-body px-md-5">
    //         {/* <div className="text-center">
    //                         <h2 className='mt-3'>Comtech</h2>
    //                     </div> */}

    //         {page === 1 && (
    //           <>
    //             <div className="mt-5">
    //               <label htmlFor="">
    //                 <h6>Name of Organisation</h6>
    //               </label>
    //               <input
    //                 type="text"
    //                 className="form-control"
    //                 placeholder="Your answer"
    //                 value={organizationName}
    //                 onChange={(e) => {
    //                     setOrganizationName(e.target.value);
    //                 }}
    //               />
    //             </div>
    //             <div className="mt-4">
    //               <label htmlFor="">
    //                 <h6>
    //                   What state does your organisation reside in Nigeria?
    //                 </h6>
    //               </label>
    //               <select
    //                 className="form-select"
    //                 value={location}
    //                 onChange={(e) => setLocation(e.target.value)}
    //               >
    //                 <option value="">Select state. </option>
    //                 {nigerian_states?.map((val, i) => (
    //                   <option value={val}>{val} </option>
    //                 ))}
    //               </select>
    //             </div>
    //             <div className="mt-4">
    //               <label htmlFor="">
    //                 <h6>What is the address of your organisation?</h6>
    //               </label>
    //               <input
    //                 type="text"
    //                 placeholder="Your answer"
    //                 className="form-control"
    //                 value={address}
    //                 onChange={(e) => {
    //                   setAddress(e.target.value);
    //                 }}
    //               />
    //             </div>

    //             <div className="mt-4">
    //               <label htmlFor="">
    //                 <h6>Company registration number (CAC)</h6>
    //               </label>
    //               <input
    //                 type="text"
    //                 placeholder="Your answer"
    //                 className="form-control"
    //                 value={registrationNumber}
    //                 onChange={(e) => {
    //                   setRegistrationNumber(e.target.value);
    //                 }}
    //               />
    //             </div>

    //             <div className="mt-5">
    //               <label htmlFor="email">
    //                 <h6>Your organisation's website</h6>
    //               </label>
    //               <input
    //                 type="text"
    //                 placeholder="Your answer"
    //                 className="form-control"
    //                 value={website}
    //                 onChange={(e) => {
    //                   setWebsite(e.target.value);
    //                 }}
    //               />
    //             </div>

    //             {/* <div className='mt-4'>
    //                                 <label htmlFor="ageRange"><h6>Age Range</h6></label>
    //                                 <select className='form-select' value={age} onChange={(age) => setAge(age.target.value)}>
    //                                     <option value="">Select age range </option>
    //                                     <option value="<18">Less than 18 years</option>
    //                                     <option value="18-25">18 - 25 years </option>
    //                                     <option value="26-35">26 - 35 years </option>
    //                                     <option value="36-45">36- 45 years </option>
    //                                     <option value=">45">Above 45  </option>

    //                                 </select>
    //                             </div> */}
    //           </>
    //         )}
    //         {page === 2 && (
    //           <>
    //             <div className="mt-5">
    //               <label htmlFor="">
    //                 <h6>
    //                   Please share links to your organisation's social media
    //                   pages (Facebook, Twitter, Instagram, Linkedin etc)
    //                 </h6>
    //               </label>
    //               <input
    //                 type="text"
    //                 placeholder="Your answer"
    //                 className="form-control"
    //                 value={links}
    //                 onChange={(e) => {
    //                   setLinks(e.target.value);
    //                 }}
    //               />
    //             </div>

    //             <div className="mt-5">
    //               <label htmlFor="">
    //                 <h6>
    //                   Full Name of your organisation's POC (point of contact
    //                   person)
    //                 </h6>
    //               </label>
    //               <input
    //                 type="text"
    //                 placeholder="Your answer"
    //                 className="form-control"
    //                 value={pocName}
    //                 onChange={(e) => {
    //                   setPocName(e.target.value);
    //                 }}
    //               />
    //             </div>

    //             <div className="mt-5">
    //               <label htmlFor="">
    //                 <h6>
    //                   Email address of your organisation's POC (point of contact
    //                   person)
    //                 </h6>
    //               </label>
    //               <input
    //                 type="text"
    //                 placeholder="Your answer"
    //                 className="form-control"
    //                 value={pocAddress}
    //                 onChange={(e) => {
    //                   setPocAddress(e.target.value);
    //                 }}
    //               />
    //             </div>

    //             <div className="mt-4">
    //               <label htmlFor="">
    //                 <h6>
    //                   Phone number of your organisation's POC (point of contact
    //                   person)
    //                 </h6>
    //               </label>
    //               <input
    //                 type="text"
    //                 placeholder="Your answer"
    //                 className="form-control"
    //                 value={pocPhone}
    //                 onChange={(e) => {
    //                   setPocPhone(e.target.value);
    //                 }}
    //               />
    //             </div>

    //             {/* <div className='mt-5'>
    //                                 <label htmlFor="email"><h6>Email</h6></label>
    //                                 <input type='email' className='form-control' value={email}
    //                                     onChange={(e) => {
    //                                         setEmail(e.target.value)
    //                                     }}
    //                                 />
    //                             </div>
    //                             <div className='mt-4'>
    //                                 <label htmlFor="phoneNumber"><h6>Phone number</h6></label>
    //                                 <input type='tel' className='form-control' value={phone}
    //                                     onChange={(e) => {
    //                                         setPhone(e.target.value)
    //                                     }}
    //                                 />
    //                             </div>

    //                             <div className='mt-4'>
    //                                 <label htmlFor="gender"><h6>What state do you reside  ?</h6></label>
    //                                 <select className='form-select' value={location} onChange={(e) => setLocation(e.target.value)}>
    //                                     <option value="">Select state. </option>
    //                                     {nigerian_states?.map((val, i) => (
    //                                         <option value={val}>{val} </option>
    //                                     ))}

    //                                 </select>
    //                             </div>
    //                             <div className='mt-4'>
    //                                 <label htmlFor="address"><h6>Address</h6></label>
    //                                 <input type='tel' className='form-control' value={address}
    //                                     onChange={(e) => {
    //                                         setAddress(e.target.value)
    //                                     }}
    //                                 />
    //                             </div>

    //                             <div className='mt-4'>
    //                                 <label htmlFor="gender"><h6>State of Origin  ?</h6></label>
    //                                 <select className='form-select' value={origin} onChange={(e) => setOrigin(e.target.value)}>
    //                                     <option value="">Select state. </option>
    //                                     {nigerian_states?.map((val, i) => (
    //                                         <option value={val}>{val} </option>
    //                                     ))}

    //                                 </select>
    //                             </div> */}
    //           </>
    //         )}
    //         {page === 3 && (
    //           <>
    //             <div className="mt-4">
    //               <label htmlFor="">
    //                 <h6>
    //                   How long has your company been delivering training
    //                   programs?
    //                 </h6>
    //               </label>
    //               <select
    //                 className="form-select"
    //                 value={programYear}
    //                 onChange={(e) => setProgramYear(e.target.value)}
    //               >
    //                 <option value="">Choose </option>
    //                 <option value={"1 Year"}>1 Year</option>
    //                 <option value={"2 Years"}>2 Years</option>
    //                 <option value={"3 Years"}>3 Years</option>
    //                 <option value={"4 Years"}>4 Years</option>
    //                 <option value={"5 Years"}>5 Years+</option>
    //               </select>
    //             </div>

    //             <div className="mt-4">
    //               <label htmlFor="">
    //                 <h6>Total number of staff in your organisation</h6>
    //               </label>
    //               <select
    //                 className="form-select"
    //                 value={staffNumber}
    //                 onChange={(e) => setStaffNumber(e.target.value)}
    //               >
    //                 <option value="">Choose </option>
    //                 {no_of_staffs?.map((val, i) => (
    //                   <option value={val}>{val} </option>
    //                 ))}
    //               </select>
    //             </div>

    //             <div className="mt-4">
    //               <label htmlFor="">
    //                 <h6>
    //                   What course(s) do you have the expertise to deliver
    //                   training?
    //                 </h6>
    //               </label>
    //               {courses_offered.map((course) => (
    //                 <div
    //                   className="input-group align-items-center"
    //                   key={course}
    //                 >
    //                   <input
    //                     type="checkbox"
    //                     name="computerAccess"
    //                     value={course}
    //                     onChange={(e) => {
    //                         if(e.target.checked){
    //                             setExpertiseCourse((prev) => [...prev, e.target.value])
    //                         }else{
    //                             const selected = expertiseCourse.filter((courses) => courses !== e.target.value);
    //                             setExpertiseCourse(selected)
    //                         }

    //                     }}
    //                   />
    //                   <p className="mt-3 ms-2">{course}</p>
    //                 </div>
    //               ))}
    //               {/* <div className="input-group align-items-center">
    //                                     <input type="radio" name="computerAccess" value="No" onChange={(e) => setComputerAccess(e.target.value)} />
    //                                     <p className='mt-3 ms-2'>No</p>
    //                                 </div> */}
    //             </div>

    //             <div className="mt-4">
    //               <label htmlFor="">
    //                 <h6>
    //                   Kindly share a prior track record of your organisation's
    //                   training delivery in the selected courses above i.e. share
    //                   proof of existing programs successfully executed in the
    //                   courses you selected above. Feel free to share viewable
    //                   links where applicable.
    //                 </h6>
    //               </label>
    //               <input
    //                 type="text"
    //                 placeholder="Your answer"
    //                 className="form-control"
    //                 value={track}
    //                 onChange={(e) => {
    //                   setTrack(e.target.value);
    //                 }}
    //               />
    //             </div>

    //             {/* <div className='mt-4'>
    //                                 <label htmlFor="educationalLevel"><h6>What is your highest level of education ?</h6></label>
    //                                 <select className='form-select' value={educationalLevel} onChange={(e) => setEducationalLevel(e.target.value)}>
    //                                     <option value="">Choose Educational level. </option>
    //                                     {educationData?.map((val, i) => (
    //                                         <option key={i} value={val}>{val} </option>
    //                                     ))}

    //                                 </select>
    //                             </div>

    //                             <div className='mt-4'>
    //                                 <label htmlFor="skillLevel"><h6>What technical skill level do you think you are currently at ?</h6></label>
    //                                 <select className='form-select' value={technicalLevel} onChange={(e) => setTechnicalLevel(e.target.value)}>
    //                                     <option value="">Choose your technical skill level</option>

    //                                     <option value="Beginner level (Zero 0 or less than 1year programming experience)">
    //                                         Beginner level (Zero 0 or less than 1year programming experience)
    //                                     </option>
    //                                     <option value="Intermediate level (1 to 4 years programming experience)">
    //                                         Intermediate level (1 to 4 years programming experience)
    //                                     </option>
    //                                     <option value="Advanced level (4+ years of programming experience)">
    //                                         Advanced level (4+ years of programming experience)
    //                                     </option>
    //                                     <option value="Expert level (7+ years of programming experience)">
    //                                         Expert level (7+ years of programming experience)
    //                                     </option>
    //                                 </select>
    //                             </div> */}

    //             {/* <div className='mt-4'>
    //                                 <label htmlFor="employmentStatus"><h6>What is your employment status ?</h6></label>
    //                                 <select className='form-select' value={employmentStatus} onChange={(e) => setEmploymentStatus(e.target.value)}>
    //                                     <option value="">Select employment status. </option>

    //                                     <option value="Student">
    //                                         Student
    //                                     </option>
    //                                     <option value="Unemployed">
    //                                         Unemployed
    //                                     </option>
    //                                     <option value="Employed (part time)">
    //                                         Employed (part time)
    //                                     </option>
    //                                     <option value="Employed (Full time)">
    //                                         Employed (Full time)
    //                                     </option>
    //                                     <option value="Self employed (Business Owner)">
    //                                         Self employed (Business Owner)
    //                                     </option>
    //                                 </select>
    //                             </div> */}
    //           </>
    //         )}

    //         {page === 4 && (
    //           <>
    //             <div className="mt-4">
    //               <label htmlFor="">
    //                 <h6>
    //                   Kindly share an overview of your training delivery team,
    //                   showing an evidence of their expertise in success delivery
    //                   of selected courses. (Feel free to share links to profiles
    //                   & accomplishments where applicable, we encourage detailed
    //                   breakdown of your team)
    //                 </h6>
    //               </label>
    //               <input
    //                 type="text"
    //                 placeholder="Your answer"
    //                 className="form-control"
    //                 value={delivery}
    //                 onChange={(e) => {
    //                   setDelivery(e.target.value);
    //                 }}
    //               />
    //             </div>

    //             <div className="mt-4">
    //               <label htmlFor="">
    //                 <h6>
    //                   Please share a viewable link to the training curriculum of
    //                   the courses you selected above.
    //                 </h6>
    //               </label>
    //               <input
    //                 type="text"
    //                 placeholder="Your answer"
    //                 className="form-control"
    //                 value={link}
    //                 onChange={(e) => {
    //                   setLink(e.target.value);
    //                 }}
    //               />
    //             </div>

    //             <div className="mt-4">
    //               <label htmlFor="address">
    //                 <h6>
    //                   Kindly share (with proof) if you have the infrastructure
    //                   (space, equipment, work tools etc) to deliver technical
    //                   trainings at your office
    //                   <br /> <br />
    //                   Type "Nil" if you do not have.
    //                 </h6>
    //               </label>
    //               <input
    //                 type="text"
    //                 placeholder="Your answer"
    //                 className="form-control"
    //                 value={proof}
    //                 onChange={(e) => {
    //                   setProof(e.target.value);
    //                 }}
    //               />
    //             </div>

    //             <div className="mt-4">
    //               <label htmlFor="">
    //                 <h6>
    //                   If you do not have the infrastructure (space, equipment,
    //                   work tools etc) to deliver technical trainings at your
    //                   office, kindly share the name & address of a partner
    //                   organisation you are working with who has the
    //                   infrastructure (with proof).
    //                   <br /> <br />
    //                   Type N/A if you showed proof in the previous question.
    //                   <br /> <br />
    //                 </h6>
    //               </label>
    //               <input
    //                 type="text"
    //                 placeholder="Your answer"
    //                 className="form-control"
    //                 value={infrastructure}
    //                 onChange={(e) => {
    //                   setInfrastructure(e.target.value);
    //                 }}
    //               />
    //             </div>

    //             <div className="mt-4">
    //               <label htmlFor="">
    //                 <h6>
    //                   What is the maximum trainee capacity at your office space
    //                   or partner organisation's space?
    //                 </h6>
    //               </label>
    //               {trainees.map((trainee) => (
    //                 <div className="input-group align-items-center">
    //                   <input
    //                     type="radio"
    //                     name="computerAccess"
    //                     value={trainee}
    //                     onChange={(e) => setCapacity(e.target.value)}
    //                   />
    //                   <p className="mt-3 ms-2">{trainee}</p>
    //                 </div>
    //               ))}
    //               {/* <div className="input-group align-items-center">
    //                                     <input type="radio" name="computerAccess" value="Yes" onChange={(e) => setComputerAccess(e.target.value)} />
    //                                     <p className='mt-3 ms-2'>Yes</p>
    //                                 </div>
    //                                 <div className="input-group align-items-center">
    //                                     <input type="radio" name="computerAccess" value="No" onChange={(e) => setComputerAccess(e.target.value)} />
    //                                     <p className='mt-3 ms-2'>No</p>
    //                                 </div> */}
    //             </div>

    //             <div className="mt-4">
    //               <label htmlFor="">
    //                 <h6>
    //                   Kindly show your capacity and track record to deliver a
    //                   blended learning model i.e. a hybrid model which is an
    //                   online and classroom (in-person) method of delivering
    //                   training programs.
    //                   <br /> <br />
    //                   We welcome a detailed overview.
    //                 </h6>
    //               </label>
    //               <input
    //                 type="text"
    //                 placeholder="Your answer"
    //                 className="form-control"
    //                 value={model}
    //                 onChange={(e) => {
    //                   setModel(e.target.value);
    //                 }}
    //               />
    //             </div>

    //             <div className="mt-4">
    //               <label htmlFor="">
    //                 <h6>
    //                   Do you have a network of mentors who can foster
    //                   peer-to-peer group learning during the course of the
    //                   training program (the trainers/facilitators should not be
    //                   included)?
    //                   <br /> <br />
    //                   If yes, kindly provide a detailed overview of your mentor
    //                   network.
    //                 </h6>
    //               </label>
    //               <input
    //                 type="text"
    //                 placeholder="Your answer"
    //                 className="form-control"
    //                 value={network}
    //                 onChange={(e) => {
    //                   setNetwork(e.target.value);
    //                 }}
    //               />
    //             </div>

    //             {/* <div className='mt-4'>
    //                                 <label htmlFor="course"><h6>What course are you interested in learning ?</h6></label>
    //                                 <select className='form-select' value={course} onChange={(e) => setCourse(e.target.value)}>
    //                                     <option value="">Choose Course Interest. </option>
    //                                     {courseInterest?.map((val, i) => (
    //                                         <option key={i} value={val}>{val} </option>
    //                                     ))}

    //                                 </select>
    //                             </div>

    //                             <div className='mt-4'>
    //                                 <label htmlFor="computerAccess"><h6>Do you own or have access to a computer for this program?</h6></label>
    //                                 <div className="input-group align-items-center">
    //                                     <input type="radio" name="computerAccess" value="Yes" onChange={(e) => setComputerAccess(e.target.value)} />
    //                                     <p className='mt-3 ms-2'>Yes</p>
    //                                 </div>
    //                                 <div className="input-group align-items-center">
    //                                     <input type="radio" name="computerAccess" value="No" onChange={(e) => setComputerAccess(e.target.value)} />
    //                                     <p className='mt-3 ms-2'>No</p>
    //                                 </div>
    //                             </div> */}

    //             {/* <div className='mt-4'>
    //                                 <label htmlFor="internetAccess"><h6>Do you have access to quality internet for this program?</h6></label>
    //                                 <div className="input-group align-items-center">
    //                                     <input type="radio" name="internetAccess" value="Yes" onChange={(e) => setInternetAccess(e.target.value)} />
    //                                     <p className='mt-3 ms-2'>Yes</p>
    //                                 </div>
    //                                 <div className="input-group align-items-center">
    //                                     <input type="radio" name="internetAccess" value="No" onChange={(e) => setInternetAccess(e.target.value)} />
    //                                     <p className='mt-3 ms-2'>No</p>
    //                                 </div>
    //                             </div>

    //                             <div className='mt-4'>
    //                                 <label htmlFor="commitment"><h6>Are you able to commit to 5+ hours per week on this program?</h6></label>
    //                                 <div className="input-group align-items-center">
    //                                     <input type="radio" name="internetAccess" value="Yes" onChange={(e) => setCommitment(e.target.value)} />
    //                                     <p className='mt-3 ms-2'>Yes</p>
    //                                 </div>
    //                                 <div className="input-group align-items-center">
    //                                     <input type="radio" name="internetAccess" value="No" onChange={(e) => setCommitment(e.target.value)} />
    //                                     <p className='mt-3 ms-2'>No</p>
    //                                 </div>
    //                             </div>

    //                             <div className='mt-4'>
    //                                 <label htmlFor="reason"><h6>Tell us what you plan to accomplish by joining this program</h6></label>
    //                                 <input type="text" className='form-control' value={reason} onChange={(e) => setReason(e.target.value)} />
    //                             </div> */}
    //           </>
    //         )}

    //         {/* {page === 5 && (
    //           <>
    //             <div className="mt-4">
    //               <label htmlFor="course">
    //                 <h6>What course are you interested in learning ?</h6>
    //               </label>
    //               <select
    //                 className="form-select"
    //                 value={course}
    //                 onChange={(e) => setCourse(e.target.value)}
    //               >
    //                 <option value="">Choose Course Interest. </option>
    //                 {courseInterest?.map((val, i) => (
    //                   <option key={i} value={val}>
    //                     {val}{" "}
    //                   </option>
    //                 ))}
    //               </select>
    //             </div>

    //             <div className="mt-4">
    //               <label htmlFor="computerAccess">
    //                 <h6>
    //                   Do you own or have access to a computer for this program?
    //                 </h6>
    //               </label>
    //               <div className="input-group align-items-center">
    //                 <input
    //                   type="radio"
    //                   name="computerAccess"
    //                   value="Yes"
    //                   onChange={(e) => setComputerAccess(e.target.value)}
    //                 />
    //                 <p className="mt-3 ms-2">Yes</p>
    //               </div>
    //               <div className="input-group align-items-center">
    //                 <input
    //                   type="radio"
    //                   name="computerAccess"
    //                   value="No"
    //                   onChange={(e) => setComputerAccess(e.target.value)}
    //                 />
    //                 <p className="mt-3 ms-2">No</p>
    //               </div>
    //             </div>

    //             <div className="mt-4">
    //               <label htmlFor="internetAccess">
    //                 <h6>
    //                   Do you have access to quality internet for this program?
    //                 </h6>
    //               </label>
    //               <div className="input-group align-items-center">
    //                 <input
    //                   type="radio"
    //                   name="internetAccess"
    //                   value="Yes"
    //                   onChange={(e) => setInternetAccess(e.target.value)}
    //                 />
    //                 <p className="mt-3 ms-2">Yes</p>
    //               </div>
    //               <div className="input-group align-items-center">
    //                 <input
    //                   type="radio"
    //                   name="internetAccess"
    //                   value="No"
    //                   onChange={(e) => setInternetAccess(e.target.value)}
    //                 />
    //                 <p className="mt-3 ms-2">No</p>
    //               </div>
    //             </div>

    //             <div className="mt-4">
    //               <label htmlFor="commitment">
    //                 <h6>
    //                   Are you able to commit to 5+ hours per week on this
    //                   program?
    //                 </h6>
    //               </label>
    //               <div className="input-group align-items-center">
    //                 <input
    //                   type="radio"
    //                   name="commitment"
    //                   value="Yes"
    //                   onChange={(e) => setCommitment(e.target.value)}
    //                 />
    //                 <p className="mt-3 ms-2">Yes</p>
    //               </div>
    //               <div className="input-group align-items-center">
    //                 <input
    //                   type="radio"
    //                   name="commitment"
    //                   value="No"
    //                   onChange={(e) => setCommitment(e.target.value)}
    //                 />
    //                 <p className="mt-3 ms-2">No</p>
    //               </div>
    //             </div>

    //             <div className="mt-4">
    //               <label htmlFor="reason">
    //                 <h6>
    //                   Tell us what you plan to accomplish by joining this
    //                   program
    //                 </h6>
    //               </label>
    //               <input
    //                 type="text"
    //                 className="form-control"
    //                 value={reason}
    //                 onChange={(e) => setReason(e.target.value)}
    //               />
    //             </div>
    //           </>
    //         )} */}
    //         <div className="mb-3 text-center">
    //           <div className="d-flex mb-5 justify-content-between">
    //             <button
    //               className="btn btn-blue-outline py-3 mt-5"
    //               style={{ width: "47%" }}
    //               onClick={prev}
    //               disabled={page < 2 ? true : false}
    //             >
    //               {" "}
    //               Previous{" "}
    //             </button>
    //             <button
    //               className="btn btn-blue px-4 py-3 mt-5"
    //               style={{ width: "47%" }}
    //               onClick={next}
    //             >
    //               {" "}
    //               {page === 4 && !isSubmitting ? 'Submit' : page === 4 && isSubmitting ? 'Please wait...' : 'Next'}
    //             </button>
    //           </div>

    //           {/* {!channel &&
    //                             <button className='btn btn-blue w-100 py-3 ' disabled> Verify</button>
    //                         }
    //                         <button className='btn btn-clear my-3' style={{ cursor: "pointer" }} onClick={clearData}>Clear Information</button> */}
    //           <p>
    //             Powered by{" "}
    //             <img src={premblyLogo} style={{ width: "80px" }} alt="" />
    //           </p>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   {showAlert &&
    //   <Alert handleClose={handleClose} />
    //   }
    // </div>
    <div>
      {successModal && <Alert handleClose={handleClose} />}
      {/* <div className="col-md-8 col-lg-5 mx-auto py-5"> */}
      <div className="py-5">
        <div
          className="card border-end-0"
          style={{ borderRadius: "70px 0px 0px 70px" }}
        >
          {/* <div className="card-header text-center" style={{ background: "rgb(0, 125, 83)" }}>
                      <img src="https://www.commtech.gov.ng/images/FMCIDE_Logo.png" width="300px" alt="" />
                  </div> */}
          <div className="card-body px-md-4">
            <div className="row" style={{ alignItems: "center" }}>
              <div className="col-md-4 provider-step">
                <h5>Provider Information</h5>

                <div className="d-flex process-tag">
                  <p
                    style={{
                      border: page > 1 ? "1px solid green" : "",
                      width: "",
                      height: "",
                    }}
                  >
                    {page > 1 ? (
                      <span style={{ color: "green" }}>&#x2713;</span>
                    ) : (
                      1
                    )}
                  </p>
                  {/* <span><i className="ri-check-line"></i></span> */}
                  <div className="desc">
                    <h6>Organisation Data</h6>
                    {/* <small>
                    Few informations about you: Name, Gender, Email ...
                  </small> */}
                  </div>
                </div>

                <div className="d-flex process-tag" style={{ background: "" }}>
                  <p style={{ border: page > 2 ? "1px solid green" : "" }}>
                    {page > 2 ? (
                      <span style={{ color: "green" }}>&#x2713;</span>
                    ) : (
                      2
                    )}
                  </p>
                  <div className="desc">
                    <h6>Contact Information</h6>
                    {/* <small>Where do you stay: Location and Address ...</small> */}
                  </div>
                </div>

                <div className="d-flex process-tag" style={{ background: "" }}>
                  <p style={{ border: page > 3 ? "1px solid green" : "" }}>
                    {page > 3 ? (
                      <span style={{ color: "green" }}>&#x2713;</span>
                    ) : (
                      3
                    )}
                  </p>
                  <div className="desc">
                    <h6>Capacity for Training Delivery</h6>
                    {/* <small>Where do you stay: Location and Address ...</small> */}
                  </div>
                </div>

                <div className="d-flex process-tag" style={{ background: "" }}>
                  <p
                    style={{
                      border: page > 4 ? "1px solid green" : "",
                      width: "30px !important",
                    }}
                  >
                    {page > 4 ? (
                      <span style={{ color: "green" }}>&#x2713;</span>
                    ) : (
                      4
                    )}
                  </p>
                  <div className="desc">
                    <h6>Proposed Course(s)</h6>
                    {/* <small>Where do you stay: Location and Address ...</small> */}
                  </div>
                </div>

                <div className="d-flex process-tag" style={{ background: "" }}>
                  <p style={{ border: page > 5 ? "1px solid green" : "" }}>
                    {page > 5 ? (
                      <span style={{ color: "green" }}>&#x2713;</span>
                    ) : (
                      5
                    )}
                  </p>
                  <div className="desc">
                    <h6>Training Experience</h6>
                    {/* <small>Where do you stay: Location and Address ...</small> */}
                  </div>
                </div>

                <div className="d-flex process-tag" style={{ background: "" }}>
                  <p style={{ border: page > 6 ? "1px solid green" : "" }}>
                    {page > 6 ? (
                      <span style={{ color: "green" }}>&#x2713;</span>
                    ) : (
                      6
                    )}
                  </p>
                  <div className="desc">
                    <h6>Training Infrastructure</h6>
                    {/* <small>Where do you stay: Location and Address ...</small> */}
                  </div>
                </div>

                <div className="d-flex process-tag" style={{ background: "" }}>
                  <p style={{ border: page > 7 ? "1px solid green" : "" }}>
                    {page > 7 ? (
                      <span style={{ color: "green" }}>&#x2713;</span>
                    ) : (
                      7
                    )}
                  </p>
                  <div className="desc">
                    <h6>Job Placements</h6>
                    {/* <small>Where do you stay: Location and Address ...</small> */}
                  </div>
                </div>
              </div>
              <div className="col-md-1">
                <hr
                  style={{
                    border: "1px solid black",
                    height: "70vh",
                    width: "1px",
                  }}
                />
              </div>
              <div className="col-md-7">
                <div className="form-div">
                  {page === 1 && (
                    <>
                      <div className="mt-5">
                        <fieldset className="form-control">
                          <legend>Name of Organisation</legend>
                          <span className="d-flex align-items-center ">
                            <i className="ri-mail-line" />
                            <input
                              type="text"
                              className="w-100"
                              value={orgName}
                              maxLength={250}
                              onChange={(e) => {
                                setOrgName(e.target.value);
                              }}
                              placeholder="Your answer"
                            />
                          </span>
                        </fieldset>
                      </div>
                      {/* <div className="mt-4">
                        <label htmlFor="">
                          <h6>
                            What state does your organisation reside in Nigeria?
                          </h6>
                        </label>
                        <select
                          className="form-select"
                          value={location}
                          onChange={(e) => setLocation(e.target.value)}
                        >
                          <option value="">Select state. </option>
                          {nigerian_states?.map((val, i) => (
                            <option value={val}>{val} </option>
                          ))}
                        </select>
                      </div> */}
                      <div className="mt-4">
                        <fieldset className="form-control">
                          <legend>
                            What state does your organisation operate in
                            Nigeria?
                          </legend>
                          <span className="d-flex align-items-center ">
                            <i className="ri-mail-line" />
                            <select
                              className="w-100"
                              value={gender}
                              onChange={(gender) => {
                                setGender(gender.target.value)
                                getLG(gender.target.value)
                              }
                              }
                            >
                              <option value="">Select</option>
                              {nigerian_states?.map((val, i) => (
                                <option value={val}>{val} </option>
                              ))}
                            </select>
                          </span>
                        </fieldset>
                      </div>
                      <div className="mt-4">
                        <fieldset className="form-control">
                          <legend>Local Government</legend>

                          <span className="d-flex align-items-center ">
                            <i className="ri-mail-line" />

                            <select
                              className="w-100"
                              value={ResidenceLocal}
                              onChange={(e) => {
                                setResidenceLocal(e.target.value);

                                // getLG(e.target.value)
                              }}
                            >
                              <option value="">Select Local Govt. </option>

                              {lgData?.map((val, i) => (
                                <option key={i} value={val}>
                                  {val}
                                </option>
                              ))}
                            </select>
                          </span>
                        </fieldset>
                      </div>
                      {/* <div className="mt-4">
                      <fieldset className="form-control">
                        <legend>Full name of Contact Person</legend>
                        <span className="d-flex align-items-center ">
                          <i className="ri-mail-line" />
                          <input
                            type="text"
                            className="w-100"
                            maxLength={250}
                            value={fullName}
                            onChange={(e) => {
                              setFullName(e.target.value);
                            }}
                            placeholder="Your answer"
                          />
                        </span>
                      </fieldset>
                    </div> */}
                      <div className="mt-4">
                        <fieldset className="form-control">
                          <legend>
                            What is the address of your organisation?
                          </legend>
                          <span className="d-flex align-items-center ">
                            <i className="ri-mail-line" />
                            <input
                              type="text"
                              className="w-100"
                              maxLength={250}
                              value={designation}
                              onChange={(e) => {
                                setDesignation(e.target.value);
                              }}
                              placeholder="Your answer"
                            />
                          </span>
                        </fieldset>
                      </div>
                      <div className="mt-4">
                        <fieldset className="form-control">
                          <legend> Organization Type </legend>
                          <span className="d-flex align-items-center ">
                            <i className="ri-mail-line" />
                            <select className="w-100" value={orgType} onChange={e => setOrgType(e.target.value)}>
                                <option value="">Select organization type</option>
                                <option value="Government-Academic-Institution">Government Academic Institution</option>
                                <option value="Non-Academic-Institution">Non-Academic Institution</option>
                                <option value="Others">Others</option>
                            </select>
                          </span>
                        </fieldset>
                      </div>
                      {(orgType && orgType !== "Government-Academic-Institution") &&
                        <div className="mt-4">
                            <fieldset className="form-control">
                            <legend>Company registration number (CAC)</legend>
                            <span className="d-flex align-items-center ">
                                <i className="ri-mail-line" />
                                <input
                                type="number"
                                className="w-100"
                                maxLength={250}
                                value={emailAddress}
                                onChange={(e) => {
                                    setEmailAdress(e.target.value);
                                }}
                                placeholder="Your answer"
                                />
                            </span>
                            </fieldset>
                        </div>
                      }
                      <div className="mt-4">
                        <fieldset className="form-control">
                          <legend>Your organisation's website</legend>
                          <span className="d-flex align-items-center ">
                            <i className="ri-mail-line" />
                            <input
                              type="url"
                              // maxLength={11}
                              className="w-100"
                              value={phoneNumber}
                              onChange={(e) => {
                                setPhoneNumber(e.target.value);
                              }}
                              placeholder="www.website.com"
                            />
                          </span>
                        </fieldset>
                      </div>
                      {/* <div className="mt-4">
                        <fieldset className="form-control">
                          <legend>Organization/Company website</legend>
                          <span className="d-flex align-items-center ">
                            <i className="ri-mail-line" />
                            <input
                              type="text"
                              maxLength={250}
                              className="w-100"
                              value={orgWebsite}
                              onChange={(e) => {
                                setOrgWebsite(e.target.value);
                              }}
                              placeholder="https://www.website.com"
                            />
                          </span>
                        </fieldset>
                      </div> */}

                      {/* <div className='mt-4'>
                                              <fieldset className="form-control">
                                                  <legend>Select Gender</legend>
                                                  <span className="d-flex align-items-center ">
                                                      <i className="ri-mail-line" />
                                                      <select className='w-100' value={gender} onChange={(gender) => setGender(gender.target.value)}>
                                                          <option value="">Select Gender </option>
                                                          <option value="male">Male </option>
                                                          <option value="Femaile">Female </option>
                                                      </select>
                                                  </span>
                                              </fieldset>
                                          </div> */}

                      {/* <div className='mt-4'>
                                              <fieldset className="form-control">
                                                  <legend>Age Range</legend>
                                                  <span className="d-flex align-items-center ">
                                                      <i className="ri-mail-line" />
                                                      <select className='w-100' value={age} onChange={(age) => setAge(age.target.value)}>
                                                          <option value="">Select age range </option>
                                                          <option value="< 18">Less than 18 years</option>
                                                          <option value="18-25">18 - 25 years </option>
                                                          <option value="26-35">26 - 35 years </option>
                                                          <option value="36-45">36- 45 years </option>
                                                          <option value="> 45">Above 45  </option>
                                                      </select>
                                                  </span>
                                              </fieldset>
                                          </div> */}
                    </>
                  )}
                  {page === 2 && (
                    <>
                      <div className="mt-4">
                        <fieldset className="form-control">
                          <legend>
                            Please share links to your organisation's social
                            media pages (Facebook, Twitter, Instagram, Linkedin
                            etc)
                          </legend>
                          <span className="d-flex align-items-center ">
                            <i className="ri-mail-line" />
                            <input
                              type="url"
                              // maxLength={11}
                              className="w-100"
                              value={orgLink}
                              onChange={(e) => {
                                setOrgLink(e.target.value);
                              }}
                              placeholder="Your answer"
                            />
                          </span>
                        </fieldset>
                      </div>
                      <div className="mt-4">
                        <fieldset className="form-control">
                          <legend>
                            Full Name of your organisation's POC (point of
                            contact person)
                          </legend>
                          <span className="d-flex align-items-center ">
                            <i className="ri-mail-line" />
                            <input
                              type="text"
                              // maxLength={11}
                              className="w-100"
                              value={orgPoc}
                              onChange={(e) => {
                                setOrgPoc(e.target.value);
                              }}
                              placeholder="Your answer"
                            />
                          </span>
                        </fieldset>
                      </div>
                      <div className="mt-4">
                        <fieldset className="form-control">
                          <legend>
                            Email address of your organisation's POC (point of
                            contact person)
                          </legend>
                          <span className="d-flex align-items-center ">
                            <i className="ri-mail-line" />
                            <input
                              type="email"
                              // maxLength={11}
                              className="w-100"
                              value={emailPoc}
                              onChange={(e) => {
                                setEmailPoc(e.target.value);
                              }}
                              placeholder="Your answer"
                            />
                          </span>
                        </fieldset>
                      </div>

                      <div className="mt-4">
                        <fieldset className="form-control">
                          <legend>
                            Phone number of your organisation's POC (point of
                            contact person)
                          </legend>
                          <span className="d-flex align-items-center ">
                            <select className=" me-2" onChange={e =>setCountryCode(e.target.value)} 
                                value={countryCode} style={{ borderRadius: "5px 0px 0px 5px", width:"70px" }}
                            >
                                {allCountriesData?.map((country, i) => (
                                    <option key={i} value={country?.code}>{getCountryFlag(country?.code)} {country?.dial_code}</option>
                                ))}
                            </select>
                            <input type="number" className="w-100" value={phonePoc} 
                                onChange={(e) => { setPhonePoc(e.target.value); }} placeholder="Your answer"
                            />
                          </span>
                        </fieldset>
                        
                        {/* <fieldset className="form-control">
                          <legend>
                            Phone number of your organisation's POC (point of
                            contact person)
                          </legend>
                          <span className="d-flex align-items-center ">
                            <input type="number" className="w-100" value={phonePoc} 
                                onChange={(e) => { setPhonePoc(e.target.value); }} placeholder="Your answer"
                            />
                          </span>
                        </fieldset> */}
                      </div>

                      {/* <div className="mt-4">
                        <label htmlFor="phoneNumber">
                          <h6>Type of Partnership (select one)</h6>
                        </label>
                        {[
                          "Monetary Support",
                          "Service-Based Support",
                          "Combination of Both",
                        ].map((trainee) => (
                          <div className="input-group align-items-center">
                            <input
                              type="radio"
                              name="monetary"
                              value={trainee}
                              onChange={(e) => setPartnership(e.target.value)}
                            />
                            <p className="mt-3 ms-2">{trainee}</p>
                          </div>
                        ))}
                      </div> */}

                      {/* <div className='mt-5'>
                                              <label htmlFor="email"><h6>Email</h6></label>
                                              <input type='email' className='form-control' value={email}
                                                  onChange={(e) => {
                                                      setEmail(e.target.value)
                                                  }}
                                              />
                                          </div>
                                          <div className='mt-4'>
                                              <label htmlFor="phoneNumber"><h6>Phone number</h6></label>
                                              <input type='tel' className='form-control' value={phone}
                                                  onChange={(e) => {
                                                      setPhone(e.target.value)
                                                  }}
                                              />
                                          </div> */}

                      {/* <div className='mt-4'>
                                              <label htmlFor="gender"><h6>What state do you reside  ?</h6></label>
                                              <select className='form-select' value={location} onChange={(e) => setLocation(e.target.value)}>
                                                  <option value="">Select state. </option>
                                                  {nigerian_states?.map((val, i) => (
                                                      <option value={val}>{val} </option>
                                                  ))}

                                              </select>
                                          </div> */}
                      {/* <div className='mt-4'>
                                              <label htmlFor="address"><h6>Address</h6></label>
                                              <input type='tel' className='form-control' value={address}
                                                  onChange={(e) => {
                                                      setAddress(e.target.value)
                                                  }}
                                              />
                                          </div> */}

                      {/* <div className='mt-4'>
                                              <label htmlFor="gender"><h6>State of Origin  ?</h6></label>
                                              <select className='form-select' value={origin} onChange={(e) => setOrigin(e.target.value)}>
                                                  <option value="">Select state. </option>
                                                  {nigerian_states?.map((val, i) => (
                                                      <option value={val}>{val} </option>
                                                  ))}

                                              </select>
                                          </div> */}
                    </>
                  )}
                  {page === 3 && (
                    <>
                      <div className="mt-4">
                        <fieldset className="form-control">
                          <legend>
                            How long has your company been delivering training
                            programs?
                          </legend>
                          <span className="d-flex align-items-center ">
                            <i className="ri-mail-line" />
                            <select
                              className="w-100"
                              value={deliveryTraining}
                              onChange={(gender) =>
                                setDeliveryTraining(gender.target.value)
                              }
                            >
                              <option value="">Select</option>
                              <option value={"1 Year"}>1 Year</option>
                              <option value={"2 Years"}>2 Years</option>
                              <option value={"3 Years"}>3 Years</option>
                              <option value={"4 Years"}>4 Years</option>
                              <option value={"5 Years"}>5 Years+</option>
                            </select>
                          </span>
                        </fieldset>
                      </div>
                      <div className="mt-4">
                        <fieldset className="form-control">
                          <legend>
                            Total number of staff in your organisation
                          </legend>
                          <span className="d-flex align-items-center ">
                            <i className="ri-mail-line" />
                            <select
                              className="w-100"
                              value={staffOrg}
                              onChange={(gender) =>
                                setStaffOrg(gender.target.value)
                              }
                            >
                              <option value="">Choose </option>
                              {no_of_staffs?.map((val, i) => (
                                <option value={val}>{val} </option>
                              ))}
                            </select>
                          </span>
                        </fieldset>
                      </div>

                      {/* 
                      <div className="mt-4">
                        <fieldset className="form-control">
                          <legend>
                            Please specify the amount you are willing to
                            contribute to the 3MTT programme (NGN)
                          </legend>
                          <span className="d-flex align-items-center ">
                            <i className="ri-mail-line" />
                            <input
                              type="number"
                              maxLength={250}
                              className="w-100"
                              value={amount}
                              onChange={(e) => {
                                setAmount(e.target.value);
                              }}
                              placeholder="Your answer"
                            />
                          </span>
                        </fieldset>
                      </div> */}

                      {/* <div className="mt-4">
                        <label htmlFor="phoneNumber">
                          <h6>What type of monetary contribution?</h6>
                        </label>
                        {[
                          "One-time donation (NGN)",
                          "Periodic contribution (NGN)",
                        ].map((trainee) => (
                          <div className="input-group align-items-center">
                            <input
                              type="radio"
                              name="contribution"
                              value={trainee}
                              onChange={(e) => setContribution(e.target.value)}
                            />
                            <p className="mt-3 ms-2">{trainee}</p>
                          </div>
                        ))}
                      </div> */}

                      {/* <div className="mt-4">
                        <fieldset className="form-control">
                          <legend>
                            How would you like your contribution to be allocated
                            within the 3MTT programme?
                          </legend>
                          <span className="d-flex align-items-center ">
                            <i className="ri-mail-line" />
                            <input
                              type="text"
                              maxLength={250}
                              className="w-100"
                              value={allocation}
                              onChange={(e) => {
                                setAllocation(e.target.value);
                              }}
                              placeholder="Your answer"
                            />
                          </span>
                        </fieldset>
                      </div> */}

                      {/* <div className="mt-4">
                      <label htmlFor="skillLevel">
                        <h6>
                          What technical skill level do you think you are
                          currently at ?
                        </h6>
                      </label>
                      <select
                        className="form-select"
                        value={technicalLevel}
                        onChange={(e) => setTechnicalLevel(e.target.value)}
                      >
                        <option value="">
                          Choose your technical skill level
                        </option>

                        <option value="Beginner level (Zero 0 or less than 1year programmeming experience)">
                          Beginner level (Zero 0 or less than 1year
                          programmeming experience)
                        </option>
                        <option value="Intermediate level (1 to 4 years programmeming experience)">
                          Intermediate level (1 to 4 years programmeming
                          experience)
                        </option>
                        <option value="Advanced level (4+ years of programmeming experience)">
                          Advanced level (4+ years of programmeming experience)
                        </option>
                        <option value="Expert level (7+ years of programmeming experience)">
                          Expert level (7+ years of programmeming experience)
                        </option>
                      </select>
                    </div> */}

                      {/* <div className="mt-4">
                      <label htmlFor="employmentStatus">
                        <h6>What is your employment status ?</h6>
                      </label>
                      <select
                        className="form-select"
                        value={employmentStatus}
                        onChange={(e) => setEmploymentStatus(e.target.value)}
                      >
                        <option value="">Select employment status. </option>

                        <option value="Student">Student</option>
                        <option value="Unemployed">Unemployed</option>
                        <option value="Employed (part time)">
                          Employed (part time)
                        </option>
                        <option value="Employed (Full time)">
                          Employed (Full time)
                        </option>
                        <option value="Self employed (Business Owner)">
                          Self employed (Business Owner)
                        </option>
                      </select>
                    </div> */}
                    </>
                  )}

                  {page === 4 && (
                    <>
                      <div className="mt-4">
                        <label htmlFor="">
                          <h6>
                            What course(s) do you have the expertise to deliver
                            training?
                          </h6>
                        </label>
                        {courses_offered.map((course) => (
                          <div
                            className="input-group align-items-center"
                            style={{display: 'flex', alignItems: 'center'}}
                            key={course}
                          >
                            <input
                              type="checkbox"
                              name="computerAccess"
                              value={course}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setExpertiseCourse((prev) => [
                                    ...prev,
                                    e.target.value,
                                  ]);
                                } else {
                                  const selected = expertiseCourse.filter(
                                    (courses) => courses !== e.target.value
                                  );
                                  setExpertiseCourse(selected);
                                }
                              }}
                            />
                            <p className="mt-2 ms-2 mb-0">{course}</p>
                          </div>
                        ))}
                        {/* <div className="input-group align-items-center">
                                         <input type="radio" name="computerAccess" value="No" onChange={(e) => setComputerAccess(e.target.value)} />
                                         <p className='mt-3 ms-2'>No</p>
                                     </div> */}
                      </div>

                      {/* <div className="mt-4">
                        <fieldset className="form-control">
                          <legend>
                            Please describe the services your organization can
                            provide to support the training programme
                          </legend>
                          <span className="d-flex align-items-center ">
                            <i className="ri-mail-line" />
                            <input
                              type="text"
                              maxLength={250}
                              className="w-100"
                              value={services}
                              onChange={(e) => {
                                setServices(e.target.value);
                              }}
                              placeholder="Your answer"
                            />
                          </span>
                        </fieldset>
                      </div> */}
                      {/* <div className="mt-4">
                        <fieldset className="form-control">
                          <legend>
                            Estimated value (in NGN monetary terms) of the
                            service-based support you are offering
                          </legend>
                          <span className="d-flex align-items-center ">
                            <i className="ri-mail-line" />
                            <input
                              type="tel"
                              className="w-100"
                              value={support}
                              maxLength={250}
                              onChange={(e) => {
                                setSupport(e.target.value);
                              }}
                              placeholder="Your answer"
                            />
                          </span>
                        </fieldset>
                      </div> */}

                      {/* <div className="mt-4">
                      <label htmlFor="course">
                        <h6>What course are you interested in learning ?</h6>
                      </label>
                      <select
                        className="form-select"
                        value={course}
                        onChange={(e) => setCourse(e.target.value)}
                      >
                        <option value="">Choose Course Interest. </option>
                        {courseInterest?.map((val, i) => (
                          <option key={i} value={val}>
                            {val}{" "}
                          </option>
                        ))}
                      </select>
                    </div> */}

                      {/* <div className="mt-4">
                      <label htmlFor="computerAccess">
                        <h6>
                          Do you own or have access to a computer for this
                          programme?
                        </h6>
                      </label>
                      <div className="input-group align-items-center">
                        <input
                          type="radio"
                          name="computerAccess"
                          value="Yes"
                          onChange={(e) => setComputerAccess(e.target.value)}
                        />
                        <p className="mt-3 ms-2">Yes</p>
                      </div>
                      <div className="input-group align-items-center">
                        <input
                          type="radio"
                          name="computerAccess"
                          value="No"
                          onChange={(e) => setComputerAccess(e.target.value)}
                        />
                        <p className="mt-3 ms-2">No</p>
                      </div>
                    </div> */}

                      {/* <div className="mt-4">
                      <label htmlFor="internetAccess">
                        <h6>
                          Do you have access to quality internet for this
                          programme?
                        </h6>
                      </label>
                      <div className="input-group align-items-center">
                        <input
                          type="radio"
                          name="internetAccess"
                          value="Yes"
                          onChange={(e) => setInternetAccess(e.target.value)}
                        />
                        <p className="mt-3 ms-2">Yes</p>
                      </div>
                      <div className="input-group align-items-center">
                        <input
                          type="radio"
                          name="internetAccess"
                          value="No"
                          onChange={(e) => setInternetAccess(e.target.value)}
                        />
                        <p className="mt-3 ms-2">No</p>
                      </div>
                    </div> */}

                      {/* <div className="mt-4">
                      <label htmlFor="commitment">
                        <h6>
                          Are you able to commit to 5+ hours per week on this
                          programme?
                        </h6>
                      </label>
                      <div className="input-group align-items-center">
                        <input
                          type="radio"
                          name="commitment"
                          value="Yes"
                          onChange={(e) => setCommitment(e.target.value)}
                        />
                        <p className="mt-3 ms-2">Yes</p>
                      </div>
                      <div className="input-group align-items-center">
                        <input
                          type="radio"
                          name="commitment"
                          value="No"
                          onChange={(e) => setCommitment(e.target.value)}
                        />
                        <p className="mt-3 ms-2">No</p>
                      </div>
                    </div> */}

                      {/* <div className="mt-4">
                      <label htmlFor="reason">
                        <h6>
                          Tell us what you plan to accomplish by joining this
                          programme
                        </h6>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                      />
                    </div> */}
                    </>
                  )}

                  {page === 5 && (
                    <>
                      <div className="mt-4">
                        <fieldset className="form-control">
                          <legend>
                            Provide prior track record of your organisation's
                            training delivery in select courses i.e. share proof
                            of existing programs successfully executed. Feel
                            free to share viewable links where applicable.
                          </legend>
                          <span className="d-flex align-items-center ">
                            <i className="ri-mail-line" />
                            <textarea className="w-100" placeholder="Your answer" 
                              value={trackRecord}
                              maxLength={2000}
                              onChange={(e) => {
                                setTrackRecord(e.target.value);
                              }}rows="3"
                            />
                            {/* <input
                              type="text"
                              className="w-100"
                              value={trackRecord}
                              maxLength={250}
                              onChange={(e) => {
                                setTrackRecord(e.target.value);
                              }}
                              placeholder="Your answer"
                            /> */}
                          </span>
                        </fieldset>
                      </div>
                      <div className="mt-4">
                        <fieldset className="form-control">
                          <legend>
                            Provide an overview of your training delivery team,
                            showing evidence of their expertise for a successful
                            delivery of select courses. (Feel free to share
                            links to profiles & accomplishments where
                            applicable, we encourage detailed breakdown of your
                            team)
                          </legend>
                          <span className="d-flex align-items-center ">
                            <i className="ri-mail-line" />
                            <textarea className="w-100" placeholder="Your answer" 
                              value={teamOverview}
                              maxLength={2000}
                              onChange={(e) => {
                                setTeamOverview(e.target.value);
                              }}rows="3"
                            />
                            {/* <input
                              type="text"
                              className="w-100"
                              value={teamOverview}
                              maxLength={250}
                              onChange={(e) => {
                                setTeamOverview(e.target.value);
                              }}
                              placeholder="Your answer"
                            /> */}
                          </span>
                        </fieldset>
                      </div>
                      <div className="mt-4">
                        <fieldset className="form-control">
                          <legend>
                            What is your capacity and track record to deliver a
                            blended learning model i.e. a hybrid model which is
                            an online and classroom (in-person) method of
                            delivering training programs.
                          </legend>
                          <span className="d-flex align-items-center ">
                            <i className="ri-mail-line" />
                            <textarea className="w-100" placeholder="Your answer" 
                              value={capacityRecord}
                              maxLength={2000}
                              onChange={(e) => {
                                setCapacityRecord(e.target.value);
                              }}rows="3"
                            />
                            {/* <input
                              type="text"
                              className="w-100"
                              value={capacityRecord}
                              maxLength={250}
                              onChange={(e) => {
                                setCapacityRecord(e.target.value);
                              }}
                              placeholder="Your answer"
                            /> */}
                          </span>
                        </fieldset>
                      </div>
                      {/* <div className="mt-4">
                        <fieldset className="form-control">
                          <legend>
                          Please share a viewable link to the training curriculum of
    the courses you selected above.
                          </legend>
                          <span className="d-flex align-items-center ">
                            <i className="ri-mail-line" />
                            <input
                              type="text"
                              className="w-100"
                              value={benefit}
                              maxLength={250}
                              onChange={(e) => {
                                setBenefit(e.target.value);
                              }}
                              placeholder="Your answer"
                            />
                          </span>
                        </fieldset>
                      </div> */}

                      {/* <div className="mt-5">
                      <label htmlFor="course">
                        <h6>
                          Select how you want us to verify your information
                        </h6>
                      </label>
                      <select
                        className="form-select"
                        value={verifMethod}
                        onChange={(e) => setVerifMethod(e.target.value)}
                      >
                        <option value="">Choose verification method. </option>
                        <option value="BVN">
                          {" "}
                          Bank Verification Number (BVN)
                        </option>
                        <option value="NIN">
                          {" "}
                          National Identity Number (NIN){" "}
                        </option>
                      </select>
                    </div> */}

                      {/* <>
                      {verifMethod === "NIN" && (
                        <div className="mt-4">
                          <label htmlFor="nin">
                            <h6>NIN</h6>
                          </label>
                          <input
                            type="tel"
                            className="form-control"
                            value={ninData}
                            onChange={(e) => {
                              setNinData(e.target.value);
                            }}
                          />
                        </div>
                      )}

                      {verifMethod === "BVN" && (
                        <div className="mt-4">
                          <label htmlFor="bvn">
                            <h6>BVN</h6>
                          </label>
                          <input
                            type="tel"
                            className="form-control"
                            value={bvnData}
                            onChange={(e) => {
                              setBvnData(e.target.value);
                            }}
                            placeholder="12345678909"
                          />
                        </div>
                      )}
                    </> */}

                      {/* <div className="mt-4">
                      <label htmlFor="githubLink">
                        <h6>
                          Share a link to projects you've previously worked
                          on. (e.g a viewable link to your projects, Github
                          profile, bitbucket profile etc)
                        </h6>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={githubLink}
                        onChange={(e) => {
                          setGithubLink(e.target.value);
                        }}
                        placeholder="https://github.com/testgithublink"
                      />
                    </div> */}
                    </>
                  )}
                  {page === 6 && (
                    <>
                      <div className="mt-4">
                        <fieldset className="form-control">
                          <legend>
                            Do you have the physical infrastructure to technical
                            (space, equipment, work tools etc) to deliver
                            technical trainings in-person?
                          </legend>
                          <span className="d-flex align-items-center ">
                            <i className="ri-mail-line" />
                            <textarea className="w-100" placeholder="Your answer" 
                              value={physical}
                              maxLength={2000}
                              onChange={(e) => {
                                setPhysical(e.target.value);
                              }} rows="3"
                            />
                            {/* <input
                              type="text"
                              className="w-100"
                              value={physical}
                              maxLength={250}
                              onChange={(e) => {
                                setPhysical(e.target.value);
                              }}
                              placeholder="Your answer"
                            /> */}
                          </span>
                        </fieldset>
                      </div>
                      <div className="mt-4">
                        <fieldset className="form-control">
                          <legend>
                            If you do not have the infrastructure (space,
                            equipment, work tools etc) to deliver technical
                            trainings at your office, provide the name & address
                            of a partner organisation you are working with who
                            has the infrastructure (with proof).
                          </legend>
                          <span className="d-flex align-items-center ">
                            <i className="ri-mail-line" />
                            <textarea className="w-100" placeholder="Your answer" 
                              value={toolInfra}
                              maxLength={2000}
                              onChange={(e) => {
                                setToolInfra(e.target.value);
                              }} rows="3"
                            />
                            {/* <input
                              type="text"
                              className="w-100"
                              value={toolInfra}
                              maxLength={250}
                              onChange={(e) => {
                                setToolInfra(e.target.value);
                              }}
                              placeholder="Your answer"
                            /> */}
                          </span>
                        </fieldset>
                      </div>
                      <div className="mt-4">
                        <fieldset className="form-control">
                          <legend>
                            What is the maximum trainee capacity at your office
                            space or partner organisation's space per session?
                          </legend>
                          <span className="d-flex align-items-center ">
                            <i className="ri-mail-line" />
                            <textarea className="w-100" placeholder="Your answer" 
                              value={trainingCapacity}
                              maxLength={2000}
                              onChange={(e) => {
                                setTrainingCapacity(e.target.value);
                              }} rows="3"
                            />
                            {/* <input
                              type="text"
                              className="w-100"
                              value={trainingCapacity}
                              maxLength={250}
                              onChange={(e) => {
                                setTrainingCapacity(e.target.value);
                              }}
                              placeholder="Your answer"
                            /> */}
                          </span>
                        </fieldset>
                      </div>
                    </>
                  )}
                  {/* {page === 7 && ( */}
                  <>
                    {/* <div className='mt-4'>
                                                <label htmlFor="computerAccess"><h6>
                                                What is the maximum trainee capacity at your office space
                       or partner organisation's space?
                                                  </h6></label>
                                                                 {trainees.map((trainee) => (
                    <div className="input-group align-items-center">
                      <input
                        type="radio"
                        name="computerAccess"
                        value={trainee}
                        onChange={(e) => setCapacity(e.target.value)}
                      />
                      <p className="mt-3 ms-2">{trainee}</p>
                    </div>
                  ))}
                                            </div> */}

                    {/* <div className="mt-4">
                        <fieldset className="form-control">
                          <legend>
                            Are there any additional information or questions
                            you would like to share with us regarding this
                            partnership opportunity?
                          </legend>
                          <span className="d-flex align-items-center ">
                            <i className="ri-mail-line" />
                            <input
                              type="text"
                              className="w-100"
                              value={additionInfo}
                              maxLength={250}
                              onChange={(e) => {
                                setAdditionInfo(e.target.value);
                              }}
                              placeholder="Your answer"
                            />
                          </span>
                        </fieldset>
                      </div> */}
                    {/* <div className="mt-4">
                        <fieldset className="form-control">
                          <legend>
                            Please share a viewable link to relevant documents
                            such as brochures, proposals, or other supporting
                            materials to this application (if applicable)
                          </legend>
                          <span className="d-flex align-items-center ">
                            <i className="ri-mail-line" />
                            <input
                              type="text"
                              className="w-100"
                              maxLength={250}
                              value={viewableLink}
                              onChange={(e) => {
                                setViewableLink(e.target.value);
                              }}
                              placeholder="https://www.website.com"
                            />
                          </span>
                        </fieldset>
                      </div> */}
                    {/* <div className="mt-4">
                        <label htmlFor="phoneNumber">
                          <h6>
                            By submitting this application, you confirm that the
                            information provided is accurate and that your
                            organization is interested in exploring a
                            partnership with the Federal Ministry of
                            Communications, Innovation and Digital Economy for
                            the 3MTT programme.
                          </h6>
                        </label>

                        <div className="input-group align-items-center">
                          <input
                            type="radio"
                            name="computerAccess"
                            value={true}
                            onChange={(e) => setAgreement(e.target.value)}
                          />
                          <p className="mt-3 ms-2">
                            Yes, I confirm and agree to the above statement
                          </p>
                        </div>
                      </div> */}
                  </>
                  {/* // )} */}
                  {/* {page === 8 && ( */}
                  <>
                    {/* <div className="mt-4">
                        <fieldset className="form-control">
                          <legend>
                                             Kindly show your capacity and track record to deliver a
                       blended learning model i.e. a hybrid model which is an
                       online and classroom (in-person) method of delivering
                       training programs.
                          </legend>
                          <span className="d-flex align-items-center ">
                            <i className="ri-mail-line" />
                            <input
                              type="text"
                              className="w-100"
                              value={initiatives}
                              maxLength={250}
                              onChange={(e) => {
                                setInitiatives(e.target.value);
                              }}
                              placeholder="Your answer"
                            />
                          </span>
                        </fieldset>
                      </div> */}
                    {/* <div className="mt-4">
                        <fieldset className="form-control">
                          <legend>
                                             Do you have a network of mentors who can foster
                       peer-to-peer group learning during the course of the
                       training program (the trainers/facilitators should not be
    included)?
                          </legend>
                          <span className="d-flex align-items-center ">
                            <i className="ri-mail-line" />
                            <input
                              type="text"
                              className="w-100"
                              value={initiatives}
                              maxLength={250}
                              onChange={(e) => {
                                setInitiatives(e.target.value);
                              }}
                              placeholder="Your answer"
                            />
                          </span>
                        </fieldset>
                      </div> */}
                    {/* <div className='mt-4'>
                                                <label htmlFor="computerAccess"><h6>
                                                Do you own or have access to a computer for this program?
                                                  </h6></label>
                                                                 <div className="input-group align-items-center">
     <input
                      type="radio"
                      name="computerAccess"
                      value="Yes"
                      onChange={(e) => setComputerAccess(e.target.value)}
                    />
                    <p className="mt-3 ms-2">Yes</p>
                  </div>
                  <div className="input-group align-items-center">
                    <input
                      type="radio"
                      name="computerAccess"
                      value="No"
                      onChange={(e) => setComputerAccess(e.target.value)}
                    />
                    <p className="mt-3 ms-2">No</p>
                  </div>
                                            </div> */}
                  </>
                  {/* // )} */}
                  {page === 7 && (
                    <>
                      {/* <div className='mt-4'>
                                                <label htmlFor="computerAccess"><h6>
                                                Do you have access to quality internet for this program?
                                                  </h6></label>
                                                                 <div className="input-group align-items-center">
     <input
                      type="radio"
                      name="computerAccess"
                      value="Yes"
                      onChange={(e) => setComputerAccess(e.target.value)}
                    />
                    <p className="mt-3 ms-2">Yes</p>
                  </div>
                  <div className="input-group align-items-center">
                    <input
                      type="radio"
                      name="computerAccess"
                      value="No"
                      onChange={(e) => setComputerAccess(e.target.value)}
                    />
                    <p className="mt-3 ms-2">No</p>
                  </div>
                                            </div> */}
                      {/* <div className='mt-4'>
                                                <label htmlFor="computerAccess"><h6>
                                                                   Are you able to commit to 5+ hours per week on this
                       program?
                                                  </h6></label>
                                                                 <div className="input-group align-items-center">
     <input
                      type="radio"
                      name="computerAccess"
                      value="Yes"
                      onChange={(e) => setComputerAccess(e.target.value)}
                    />
                    <p className="mt-3 ms-2">Yes</p>
                  </div>
                  <div className="input-group align-items-center">
                    <input
                      type="radio"
                      name="computerAccess"
                      value="No"
                      onChange={(e) => setComputerAccess(e.target.value)}
                    />
                    <p className="mt-3 ms-2">No</p>
                  </div>
                                            </div> */}
                      <div className="mt-4">
                        <fieldset className="form-control">
                          <legend>
                            If selected, how does your organisation intend to
                            place trained fellows in jobs during or after their
                            training program?
                          </legend>
                          <span className="d-flex align-items-center ">
                            <i className="ri-mail-line" />
                            <textarea className="w-100" placeholder="Your answer" 
                              value={orgFellow}
                              maxLength={2000}
                              onChange={(e) => {
                                setOrgFellow(e.target.value);
                              }} rows="3"
                            />
                          </span>
                        </fieldset>
                      </div>
                    </>
                  )}
                  <div className="mb-3 text-center">
                    <div className="d-flex mb-5 justify-content-between">
                      <button
                        className="btn btn-blue-outline py-3 mt-5"
                        style={{ width: "47%" }}
                        onClick={prev}
                        disabled={page < 2 || loading ? true : false}
                      >
                        {" "}
                        Previous{" "}
                      </button>
                      <button
                        className="btn btn-blue px-4 py-3 mt-5"
                        style={{ width: "47%" }}
                        onClick={next}
                        disabled={isSubmitting ? true : false}
                      >
                        {page === 7 && !isSubmitting
                          ? "Submit"
                          : page === 7 && isSubmitting
                          ? "Loading..."
                          : "Next"}
                        {/* {page === 7 ? (
                        <>
                          {loading ? (
                            <>
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>{" "}
                              Loading...
                            </>
                          ) : (
                            <>Finish</>
                          )}
                        </>
                      ) : (
                        "Next"
                      )} */}
                      </button>
                    </div>
                    <p>
                      Powered by{" "}
                      <img src={premblyLogo} style={{ width: "80px" }} alt="" />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showAlert && <Alert handleClose={handleClose} />}
    </div>
  );
}
