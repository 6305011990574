import React from "react";
import SuccessImage from "../../assets/success.gif";

export default function Alert({ handleClose }) {
  return (
    <div
      style={{
        background: "#6463637d",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100vh",
      }}
    >
        <div className="col-md-5 mx-auto">
            <div
                style={{
                width: "100%",
                margin: "10em auto",
                background: "white",
                borderRadius: "10px",
                padding: "2em",
                textAlign: "center",
                }}
            >
                <img src={SuccessImage} alt="loader" />
                <h4>Thank you</h4>
                <h4>Form submitted Successfully, Please check your email.</h4>
                <button
                className="btn btn-blue px-4 py-3 mt-5"
                style={{ width: "47%" }}
                onClick={handleClose}
                >
                Close
                </button>
            </div>
        </div>
    </div>
  );
}
